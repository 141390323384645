<template>
  <div class="grandmaster-section">
    <div class="grandmaster-img">
      <img v-if="videos.profile_path" :src="host + videos.profile_path" />
      <img src="../assets/shroud-photo.png" v-else />
    </div>
    <div class="grandmaster-content">
      <h2>{{ videos.name }}</h2>
      <p>Grandmaster</p>
      <h3>
        {{ followers }} Followers <span>{{ videos.description }}</span>
      </h3>
      <div class="share-like-box">
        <Facebook />
        <!--                <a href="#"><img src="../assets/streamer-share-fb.svg" alt=""/></a>-->
        <!--        <a onclick="showModal('shareModal')">-->
        <!--          <img src="../assets/streamer-share-fb.svg" alt="" />-->
        <!--        </a>-->
        <div class="favourite-box">
          <button
            type="button"
            class="play-voice"
            :class="{ 'pause-voice': isActive }"
            @click="wishList()"
          ></button>
        </div>
      </div>
    </div>
    <!--    <SocialShare/>-->
  </div>
</template>

<script>
// import SocialShare from "@/components/SocialShare.vue";
import Facebook from "@/components/Facebook.vue";

export default {
  name: "StreamerDetails",
  components: {
    Facebook
    // SocialShare
  },
  props: ["streamers", "host", "id", "isActive", "followers"],
  data: () => {
    return {
      videos: []
    };
  },
  created() {
    this.videos = this.$props.streamers.find(streamer => {
      return streamer.id == this.$props.id;
    });
  },
  methods: {
    wishList() {
      this.$emit("followStreamer");
    }
  }
};
</script>

<style scoped></style>
