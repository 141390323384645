<template>
  <div>
    <StreamerDetails
      :streamers="streamers"
      :isActive="isActive"
      :host="host"
      :id="id"
      :followers="followers"
      @followStreamer="wishList()"
      v-if="streamers.length"
    />
    <TopStreams :videos="videos" :host="host" v-if="videos.length" />
    <OthersStreamers
      :streamers="streamers"
      :host="host"
      :id="id"
      title="Other Spotlights"
      v-if="streamers.length"
    />
  </div>
</template>

<script>
import axios from "axios";
import StreamerDetails from "@/components/StreamerDetails.vue";
import TopStreams from "@/components/TopStreams.vue";
import OthersStreamers from "@/components/OthersStreamers.vue";

export default {
  name: "Streamer",
  components: {
    StreamerDetails,
    TopStreams,
    OthersStreamers
  },
  data: () => {
    return {
      host: null,
      id: 0,
      loading: true,
      error: false,
      message: null,
      isActive: false,
      streamers: [],
      otherStreamers: [],
      individualStreamer: {},
      videos: [],
      msisdn: "",
      followers: 0
    };
  },
  async created() {
    this.msisdn = localStorage.getItem("phone")
      ? localStorage.getItem("phone")
      : "";
    this.host = this.$root.url();
    await this.streamersProfile();
  },
  methods: {
    async streamersProfile() {
      try {
        this.id = this.$route.params.id;
        const path = "stProfile";
        let url = `${this.host}/${path}?id=${this.id}`;
        if (this.msisdn !== "") {
          url += `&msisdn=${this.msisdn}`;
        }
        console.log("url = ", url);
        let response = await axios.get(url);
        let success = response.data.success;
        if (success) {
          this.streamers = response.data.streamerInfo;
          this.videos = response.data.video;
          this.isActive = response.data.following;
          this.followers = response.data.followers;
        } else {
          let errorObject = {
            status: true,
            message: "Something wrong, please try again later"
          };
          this.errorMessage(errorObject);
        }
        this.loading = false;
      } catch (e) {
        let errorObject = {
          status: true,
          message: "Something wrong, please try again later"
        };
        this.errorMessage(errorObject);
      }
    },
    async wishList() {
      try {
        if (this.msisdn === "") {
          alert("Login to use this functionality.");
          return true;
        }
        const path = "followStreamer";
        let url = `${this.host}/${path}?streamerId=${this.id}&msisdn=${this.msisdn}`;
        let response = await axios.get(url);
        let success = response.data.success;
        if (success) {
          this.isActive = !this.isActive;
          this.followers = response.data.followers;
        }
        return true;
      } catch (e) {
        return true;
      }
    },
    errorMessage(error) {
      this.error = error.status;
      this.message = error.message;
    }
  }
};
</script>

<style scoped></style>
