<template>
  <div class="social-icons-all">
    <vue-yandex-share v-bind="options"></vue-yandex-share>
  </div>
</template>

<script>
import VueYandexShare from "@alexlit/vue-yandex-share";
export default {
  name: "Facebook",
  components: {
    VueYandexShare
  },
  data: () => {
    return {
      path: "/images/",
      options: {
        accessToken: null,
        bare: false,
        counter: false,
        copy: "last",
        description: null,
        direction: "vertical",
        hashtags: null,
        image: null,
        lang: "en",
        limit: 24,
        popupDirection: "bottom",
        popupPosition: "block",
        size: "m",
        title: null,
        url: null,
        services: ["facebook"]
      }
    };
  }
};
</script>

<style scoped></style>
