<template>
  <div>
    <div class="vd-pg-tl">
      <ul>
        <li class="vd-01">Top Streams</li>
      </ul>
    </div>
    <div class="top-stream-section">
      <ul>
        <li v-for="video in videos" :key="video.id">
          <div class="top-stream-img">
            <router-link
              :to="{ name: 'VideoPlay', params: { videoId: video.id } }"
            >
              <img :src="video.thumbnail_path" />
            </router-link>
            <div class="video-placeholder">
              <div>
                <img src="../assets/eye-icons.svg" alt="" /> {{ video.view }}
              </div>
            </div>
          </div>

          <div class="top-stream-content">
            <h4>{{ video.title }}</h4>
            <h5>{{ video.short_description }}</h5>
            <!--              <p>{{ video.conditions }}</p>-->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopStreams",
  props: ["videos", "host"]
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
